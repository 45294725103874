import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Form = props => {
  const data = useStaticQuery(graphql`
    query {
      content: contentfulFormPage {
        backgroundImage {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Helmet
        script={[
          {
            type: 'text/javascript',
            defer: true,
            innerHTML:
              '(function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/"; if(!gi.call(d,id)) { js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })()',
          },
        ]}
      />
      <Background>
        <Img fluid={data.content.backgroundImage.fluid} />
      </Background>
      <Typeform className="typeform-widget" data-url={props.dataUrl} />
    </>
  );
};

export default Form;

const Background = styled.div`
  ${p => p.theme.sm} {
    display: none;
  }
  ${p => p.theme.l} {
    position: fixed;
    bottom: 0;
    top: 38%;
    left: 0%;
    right: 0;
    overflow: hidden;
  }
`;

const Typeform = styled.div`
  max-width: 940px;
  background: #ffffff;
  margin: 20px auto 80px;
  min-height: 400px;
  height: calc(80vh - 120px);
  ${p => p.theme.l} {
    position: relative;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
  }
`;
